let youtubemovie    = require( './youtube-movie.js' );
let modalineup      = require( './moda-lineup.js' );
let bxslider        = require( './bxslider/jquery.bxslider.min.js' );
let responsive      = require( './responsive.js' );
let tab             = require( './tab.js' );
let flexlefty       = require( './flexlefty.js' );
let accordion       = require( './accordion.js' );
let yourprofits     = require( './yourprofits.js' );
let slidemenu       = require( './slidemenu.js' );
let purchasebutton  = require( './purchase-button.js' );
let headerattention = require( './header-attention.js' );
let hamburger       = require( './hamburger.js' );
let cartcheck       = require( './cart-check.js' );
let picturefill     = require( './picturefill.js' );
let shopbtn       = require( './shop-btn.js' );
let config          = require( './config.js' );

jQuery( function( $ ) {
	let pcWidth = 769;
	let winWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	let resizedWidth;
	let timer = 0;
	$( document ).ready( function() {
		resizedWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		$( ".is-hierarchy--bt" ).on( "click", function( event ) {
			event.stopPropagation();
			let icon = "." + $( this ).attr( "class" ) + "::after";
			$( this ).next( ".is-hierarchy" ).slideToggle();
			$( this ).toggleClass( "minus" );
			return false;
		} );
		if( winWidth < pcWidth ) {
			$( ".is-hierarchy--bt" ).css( "pointer-events", "auto" );
		} else {
			$( ".is-hierarchy--bt" ).css( "pointer-events", "none" );
		}
	} );
	$( window ).on( "resize", function() {
		if( timer !== false ) {
			clearTimeout( timer );
		}
		// resizedWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		timer = setTimeout( function() {
			resizedWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
			if ( winWidth != resizedWidth ) {
				if( resizedWidth > pcWidth ) {
					$( ".is-hierarchy" ).css( "display", "block" );
					$( ".is-hierarchy--bt" ).css( "pointer-events", "none" );
				} else {
					$( ".is-hierarchy--bt" ).css( "pointer-events", "auto" );
					$( ".is-hierarchy" ).css( "display", "none" );
					$( ".is-hierarchy--bt" ).removeClass( "minus" );
					$( ".is-hierarchy--bt" ).addClass( "plus" );
				}
				winWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
			}
		} , 200 );
	} );
} );

$( function() {
	$('.js-cart-btn').on('click', function(e) {
		e.preventDefault();
		var cartParam = '';
		for (var i = 0; i < $('.js-cart-check:checked').length; i++) {
			var itemId = $('.js-cart-check:checked').eq(i).val();
			cartParam += 'item_qty_' + itemId + '[qty]=1&item_id_' + itemId + '=order&';
		}
		location.href = 'https://pip-wellness.com//shop/add_to_cart/?' + cartParam + 'return_url=https://profits.pipjapan.co.jp/shop/';
	})
} );

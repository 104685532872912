jQuery( function( $ ) {
	let pcWidth = 769;
	let click_flg = true;
	$( ".is-modal-lineup" ).find( ".is-modal-lineup--bt" ).on( "click", function() {
		$( this ).addClass( "is-active" );
		if( click_flg ) {
			click_flg = false;
			$( this ).blur();
			if( $( ".is-modal-bg" )[0] ) return false;
			let activeModal = $( this ).attr( "href" );
			modalActive( activeModal, pcWidth );
			click_flg = true;
		} else {
			return false;
		}
		return false;
	} );
	let timer = 0;
	$( window ).on( "resize", function() {
		if( timer > 0 ) {
			clearTimeout( timer );
		}
		timer = setTimeout( function() {
			modalResponsive( pcWidth );
		}, 200 );
	} );
	/*
	 * モーダルの非表示
	 */
	$( ".is-modal-lineup" ).find( ".is-close" ).on( "click", function() {
		modalRemove();
	} );
	$( document ).on( "click", ".is-modal-bg", function() {
		modalRemove();
	} );
} );
/*
* モーダル表示設定
*/
function modalActive( pcWidth ) {
	$( "body" ).append( '<div class="is-modal-bg"></div>' );
	$( "body" ).css( "pointer-events", "none" );
	$( ".is-modal-bg" ).fadeIn( "slow", function() {
		$( ".is-modal-lineup--data" ).addClass( "is-active" );
		modalDisplay( pcWidth );
		$( "body" ).css( "pointer-events", "auto" );
	} );
}
/*
 * モーダル非表示
 */
function modalRemove() {
	$( ".is-modal-lineup--data" ).fadeOut( "slow", function() {
		$( ".is-modal-bg" ).remove();
		$( ".is-modal-lineup--data" ).removeClass( "is-active" );
		$( ".is-modal-lineup--bt" ).removeClass( "is-active" );
	} );
}
/*
 * モーダル出現
 */
function modalDisplay( pcWidth ) {
	let activeModal = $( ".is-modal-lineup--data" );
	let resizedWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	let objWidth = $( activeModal ).outerWidth();
	$( activeModal ).addClass( "is-active" );
	if( resizedWidth > pcWidth ) {
		$( activeModal ).offset( { left: position.left - ( objWidth / 2 ) } );
	}
}
/*
 * レスポンシブ時、位置修正
 */
function modalResponsive( pcWidth ) {
	let resizedWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	if( resizedWidth < pcWidth ) {
		modalRemove();
		$( ".is-modal-lineup" ).removeClass( "is-active" );
		// $( ".is-modal-lineup .is-modal-lineup-data" ).each( function( index ) {
		// 	if( $( this ).hasClass( "is-active" ) ) {
		// 		let position  = $( appearPositon()[index] );
		// 		let activeModal = "#" + $( this ).attr( "id" );
		// 		modalDisplay( position[0], activeModal, pcWidth );
		// 		scrollReset( $( activeModal ).offset().top );
		// 	} else {
		// 	}
		// } );
	}
}

jQuery(function($) {

	const ua = navigator.userAgent.toLowerCase(),
		  isiOs = (ua.indexOf('iphone') > -1) || (ua.indexOf('ipad') > -1),
		  isAndroid = (ua.indexOf('android') > -1);

	const buttonClass = 'js-pulldown-button',
		  wrapperClass = 'js-pulldown-wrapper',
  		  contentClass = 'js-pulldown-content',
		  openClass = 'is-show';

	if(isiOs || isAndroid) {
		$('.'+buttonClass).on('click', function() {
			$(this).next('.'+contentClass).toggleClass(openClass);
		});
	} else {
		$('.'+wrapperClass).on('mouseenter', function() {
			$(this).children('.'+contentClass).addClass(openClass);
		});
		$('.'+wrapperClass).on('mouseleave', function() {
			$(this).children('.'+contentClass).removeClass(openClass);
		});
	}
	$( ".js-pulldown-wrapper" ).find( ".is-close" ).on( "click", function() {
		$( this ).parents( ".is-show" ).removeClass( "is-show" );
	} );
} );

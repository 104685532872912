jQuery( function( $ ) {
	/*
	 * .is-modal        = モーダルウィンドウのラッパー
	 * .is-modal-data   = モーダルウィンドウ
	 * .is-modal-bt     = モーダルを表示するボタン
	 * .is-modal-arrow  = モーダルウィンドウの下側に表示する吹き出しよう三角形 （JSで出力）
	 * .is-modal-bg     = モーダル表示時の背景（JSで出力）
	 */
	let pcWidth = 769;
	let click_flg = true;
	let initOffset = [];
	$( ".is-modal .is-modal-data" ).each( function( index ) {
		initOffset.push( $( this ).offset() );
	} );

	/*
	 * href に対して該当 id のモーダルを表示
	 */
	$( ".is-modal-list" ).find( ".is-modal-bt" ).on( "click", function() {
		$( this ).addClass( "is-active" );
		if( click_flg ) {
			click_flg = false;
			$( this ).blur();
			if( $( ".is-modal-bg" )[0] ) return false;
			let position = appearPositon()[activeNum()];
			let activeModal = $( this ).attr( "href" );
			modalActive( position, activeModal, pcWidth );

			click_flg = true;
		} else {
			return false;
		}
		return false;
	} );

	let timer = 0;
	$( window ).on( "resize", function() {
		if( timer > 0 ) {
			clearTimeout( timer );
		}
		timer = setTimeout( function() {
			modalResponsive( pcWidth, initOffset );
		}, 200 );
	} );

	/*
	 * モーダルの非表示
	 */
	$( ".is-modal" ).find( ".is-close" ).on( "click", function() {
		let active = activeNum();
		modalRemove( initOffset[active] );
	} );
	$( document ).on( "click", ".is-modal-bg", function() {
		let active = activeNum();
		modalRemove( initOffset[active] );
	} );

} );

/*
 * ウィンドウ読み込み時のモーダル位置
 */
function initModal() {
	let initOffset = [];
	$( ".is-modal .is-modal-data" ).each( function( index ) {
		initOffset.push( $( this ).offset() );
	} );
	return initOffset;
}
/*
 * モーダル表示設定
 */
function modalActive( position, activeModal, pcWidth ) {

	$( "body" ).append( '<div class="is-modal-bg"></div>' );
	$( "body" ).css( "pointer-events", "none" );
	$( ".is-modal-bg" ).fadeIn( "slow", function() {
		$( activeModal ).addClass( "is-active" );
		$( activeModal ).parents( ".is-modal" ).addClass( "is-active" );

		modalDisplay( position, activeModal, pcWidth, activeNum() );

		// scrollReset( $( activeModal ).offset().top - 30 );
		$( "body" ).css( "pointer-events", "auto" );
	} );
}

/*
 * モーダル非表示
 */
function modalRemove( initOffset ) {
	$( ".is-modal, .is-modal-data, .is-modal-bt" ).find( ".is-active" ).fadeOut( "slow", function() {
		$( ".is-modal.is-active" ).removeAttr( "style" );
		$( ".is-modal-data.is-active" ).removeAttr( "style" );
		$( ".is-modal.is-active" ).removeClass( "is-active" );
		$( ".is-modal-data.is-active" ).removeClass( "is-active" );
		$( ".is-modal-list" ).find( ".is-modal-bt" ).removeClass( "is-active" );
	} );
	$( ".is-modal-bg" ).remove();
}

/*
 * スクロール位置の調整
 */

function scrollReset( modalTop ) {
	$( window ).scrollTop( modalTop );
}

/*
 * アクティブなモーダルナンバーを取得
 */
function activeNum( initOffset ) {
	let num = "";
	$( ".is-modal-list .is-modal-bt" ).each( function( index ) {
		if( $( this ).hasClass( "is-active" ) ) {
			num = index;
		}
	} );
	return num;
}

/*
 * モーダルを出現させる位置
 */
function appearPositon() {
	let appearPoint = [];
	$( ".is-modal-list .is-modal-bt" ).each( function() {
		appearPoint.push( $( this ).offset() );
	} );
	return appearPoint;
}

/*
 * モーダル出現
 */
function modalDisplay( position, activeModal, pcWidth, activeNum ) {
	let objHeight = $( activeModal ).outerHeight();
	let objWidth = $( activeModal ).outerWidth();
	let resizedWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

	if( resizedWidth < pcWidth ) {
		$( activeModal ).offset( { top: $( window ).scrollTop() + 20, left: 20 } );
	} else {
		$( activeModal ).offset( { top: $( window ).scrollTop() + ( objWidth / 4 ), left: ( window.innerWidth / 2 ) - ( objWidth / 2 )  } );
	}
}

/*
 * レスポンシブ時、位置修正
 */
function modalResponsive( pcWidth, initOffset ) {
	let resizedWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	if( resizedWidth < pcWidth || resizedWidth > pcWidth ) {
		$( ".is-modal .is-modal-data" ).each( function( index ) {
			if( $( this ).hasClass( "is-active" ) ) {
				let position  = $( appearPositon()[index] );
				let activeModal = "#" + $( this ).attr( "id" );
				modalDisplay( position[0], activeModal, pcWidth, activeNum() );
				// scrollReset( $( activeModal ).offset().top - 30 );
			} else {
			}
		} );
	}
}

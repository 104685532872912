jQuery( function( $ ) {
	/*
		bxslider
	*/
	let pcWidth = 769;
	$( ".is-slider--mainvisual" ).bxSlider( {
		mode: "fade",
		auto: true,
		controls: false,
		pager: false,
		pause: 6000,
		speed: 1200
	} );
	$( ".is-slider--overview" ).bxSlider( {
		auto: true,
		pause: 6000,
		speed: 600,
	} );
	$( ".is-slider--announce" ).bxSlider( {
		auto: true,
		pause: 6000,
		speed: 600
	} );
	$( ".is-slider--taping" ).bxSlider( {
		auto: true,
		pager: false,
		minSlides: 1,
		maxSlides: 3,
		moveSlides: 1,
		startSlide: 0,
		infiniteLoop: true,
	} );
} );

jQuery( function( $ ) {
	var playerTop01, playerTop02, playerTop03;

	window.onYouTubeIframeAPIReady = function() {
		playerTop01 = new YT.Player('youtube-player-top01', {
			height: '100%',
			width: '100%',
			videoId: 'tsOLffedFT4',
			playerVars: {
				rel: 0,
				controls: 0,
				showinfo: 0,
				modestbranding: 0
			},
			events: {
				'onReady': function() {
					$('.poster_top01').click(function() {
						if ($(this).hasClass('play')) {
							$(this).removeClass('play');
							$(this).css({'opacity':1});
							playerTop01.pauseVideo();
						}else {
							$(this).addClass('play');
							$(this).css({'opacity':0});
							playerTop01.playVideo();
						}
					});
				},
				'onStateChange': function(state) {
					if (state.data == YT.PlayerState.ENDED) {
						$('.poster_top01').removeClass('play').css({'opacity':1});
					}
				}
			}
		});
		playerTop02 = new YT.Player('youtube-player-top02', {
			height: '100%',
			width: '100%',
			videoId: 'dUqzYjyXUuY',
			playerVars: {
				rel: 0,
				controls: 0,
				showinfo: 0,
				modestbranding: 0
			},
			events: {
				'onReady': function() {
					$('.poster_top02').click(function() {
						if ($(this).hasClass('play')) {
							$(this).removeClass('play');
							$(this).css({'opacity':1});
							playerTop02.pauseVideo();
						}else {
							$(this).addClass('play');
							$(this).css({'opacity':0});
							playerTop02.playVideo();
						}
					});
				},
				'onStateChange': function(state) {
					if (state.data == YT.PlayerState.ENDED) {
						$('.poster_top02').removeClass('play').css({'opacity':1});
					}
				}
			}
		});
		playerTop03 = new YT.Player('youtube-player-top03', {
			height: '100%',
			width: '100%',
			videoId: 'C0pnRnpLIBU',
			playerVars: {
				rel: 0,
				controls: 0,
				showinfo: 0,
				modestbranding: 0
			},
			events: {
				'onReady': function() {
					$('.poster_top03').click(function() {
						if ($(this).hasClass('play')) {
							$(this).removeClass('play');
							$(this).css({'opacity':1});
							playerTop03.pauseVideo();
						}else {
							$(this).addClass('play');
							$(this).css({'opacity':0});
							playerTop03.playVideo();
						}
					});
				},
				'onStateChange': function(state) {
					if (state.data == YT.PlayerState.ENDED) {
						$('.poster_top03').removeClass('play').css({'opacity':1});
					}
				}
			}
		});

	}

	var tag = document.createElement('script');
	tag.src = "https://www.youtube.com/iframe_api";
	var firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
});

$('.js-video').each(function (index, elm) {
	var callBack = function callBack() {
		$(elm).get(0).play();
	};
	var name = 'play_' + index;
	common.inView(callBack, name, $(elm), 0);
});


$( function() {
	$( ".is-accordion" ).on( "click", function() {
		if( $( this ).hasClass( "is-plus" ) ) {
			$( this ).removeClass( "is-plus" );
			$( this ).addClass( "is-minus" );
			$( this ).next().slideToggle();
		} else {
			$( this ).removeClass( "is-minus" );
			$( this ).addClass( "is-plus" );
			$( this ).next().slideToggle();
		};
		return false;
	} );
} );

	$(function() {
		var accordion = $(".sec_lineup");
		accordion.each(function () {
			var noTargetAccordion = $(this).siblings(accordion);
			$(this).find(".is-acc").click(function() {
			$(this).next(".is-accordion--lineup").slideToggle();
			$(this).toggleClass("is-minus");
			noTargetAccordion.find(".is-accordion--lineup").slideUp();
			noTargetAccordion.find(".is-acc").removeClass("is-minus");
			});
		});
	});

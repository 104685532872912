jQuery( function( $ ) {
	let timer = 0;
	let hmHead = $( ".is-hamburger--head" ).outerHeight();
	let humheight;

	/*
	 * ハンバーガーメニュー
	 */
	$( ".is-hamburger__bt" ).click( function() {
		$( "body" ).toggleClass( "is-hamburger--active" );
		$( ".p-earth-bt" ).toggleClass( "is-earth--active" );
		if( $( ".p-hamburger" ).hasClass( "is-hamburger--active" ) ) {
			$( ".p-hamburger" ).fadeOut( 200, function() {
				$( ".is-hamburger--head" ).removeClass( "is-hamburger--active" );
				$( ".p-hamburger" ).removeClass( "is-hamburger--active" );
				$( ".is-hamburger__bg" ).removeClass( "is-hamburger--active" );
			} );
		} else {
			$( ".p-hamburger" ).fadeIn( 200, function() {
				$( ".is-hamburger--head" ).addClass( "is-hamburger--active" );
				$( ".is-hamburger__bg" ).addClass( "is-hamburger--active" );
				$( ".p-hamburger" ).addClass( "is-hamburger--active" );
			} );
		}
		$( this ).toggleClass( "is-hamburger--active" );
		$( ".is-hamburger__bg" ).css( "top", hmHead );
	} );
	$( ".is-hamburger__bg" ).click( function( e ) {
		e.stopPropagation();
		$( "body" ).toggleClass( "is-hamburger--active" );
		$( ".is-hamburger__bt" ).toggleClass( "is-hamburger--active" );
		$( ".is-hamburger__bg" ).toggleClass( "is-hamburger--active" );
	} );

	/*
	 * 多言語メニュー
	 */
	$( ".is-earth-bt" ).click( function() {
		$( "body" ).toggleClass( "is-earth--active" );
		if( $( ".p-earth" ).hasClass( "is-earth--active" ) ) {
			$( ".p-earth" ).fadeOut( 200, function() {
				$( ".is-earth--head" ).removeClass( "is-earth--active" );
				$( ".p-earth" ).removeClass( "is-earth--active" );
				$( ".is-earth__bg" ).removeClass( "is-earth--active" );
				$( ".p-earth-bt--close" ).removeClass( "is-earth--active" );
				$( ".is-hamburger__bt" ).css( { 'opacity': '1', 'z-index': 1 } );
			} );
		} else {
			$( ".p-earth" ).fadeIn( 200, function() {
				$( ".is-earth--head" ).addClass( "is-earth--active" );
				$( ".p-earth" ).addClass( "is-earth--active" );
				$( ".is-earth__bg" ).addClass( "is-earth--active" );
				$( ".p-earth-bt--close" ).addClass( "is-earth--active" );
				$( ".is-hamburger__bt" ).css( { "opacity": "0", "z-index": -1 } );
			} );
		}
		$( this ).toggleClass( "is-earth--active" );
		$( ".is-earth__bg" ).css( "top", hmHead );
	} );
	$( ".is-earth__bg" ).click( function( e ) {
		e.stopPropagation();
		$( "body" ).toggleClass( "is-earth--active" );
		$( ".is-earth-bt" ).toggleClass( "is-earth--active" );
		$( ".is-earth__bg" ).toggleClass( "is-earth--active" );
		$( ".p-earth-bt--close" ).toggleClass( "is-earth--active" );
		$( ".is-earth-bt" ).removeClass( "is-earth--active" );
	} );
	$( '.p-earth-bt--close' ).click( function( e ) {
		e.stopPropagation();
		$( "body" ).removeClass( "is-earth--active" );
		$( ".is-earth-bt" ).removeClass( "is-earth--active" );
		$( ".is-earth__bg" ).removeClass( "is-earth--active" );
		$( ".p-earth" ).removeClass( "is-earth--active" );
		$( this ).removeClass( "is-earth--active" );
		$( ".is-hamburger__bt" ).css( { "opacity": "1", "z-index": 1 } );
		$( ".is-earth-bt" ).removeClass( "is-earth--active" );
	} );

	/*
	 * ウィンドウサイズリサイズ時
	 */
	$( window ).on( "resize", function() {
		let pcWidth = 769;
		if( timer > 0 ) {
			clearTimeout( timer );
		}
		timer = setTimeout( function() {
			let resizedWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
			if( resizedWidth > pcWidth ) {
				$( "body" ).removeClass( "is-hamburger--active is-earth--active" );
				$( ".p-hamburger" ).removeClass( "is-hamburger--active" );
				$( ".is-hamburger__bt" ).removeClass( "is-hamburger--active" );
				$( ".is-hamburger__bg" ).removeClass( "is-hamburger--active" );
				$( ".is-hamburger--head" ).removeClass( "is-hamburger--active" );
				$( ".is-hamburger__bt" ).css( { "opacity": "1", "z-index": 1 } );
				$( ".p-hamburger" ).removeAttr( "style" );
				$( ".p-earth" ).removeClass( "is-earth--active" );
				$( ".is-earth-bt" ).removeClass( "is-earth--active" );
				$( ".is-earth__bg" ).removeClass( "is-earth--active" );
				$( ".is-earth--head" ).removeClass( "is-earth--active" );
				$( ".p-earth" ).removeAttr( "style" );
				$( ".p-earth-bt--close" ).css( "display", "none" );
				$( ".p-earth-bt--close" ).removeClass( "is-earth--active" );
			} else {
				$( ".p-earth-bt--close" ).css( "display", "inline-block" );
			}
		}, 200 );
	} );
} );

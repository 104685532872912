jQuery( function( $ ) {
	let pcWidth = 769; //break point for PC
	$( window ).on( "load resize", function() {
		let resizedWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if( resizedWidth < pcWidth ) {
			$( '.is-flexlefty' ).each( function( f ) {
				let $grid = $( '.is-flexlefty' ),
					emptyCells = [],
					i;
					$( this ).find( ".item" ).each( function () {
						emptyCells.push( [ f, $( '<li>', { class: 'item is-empty' } ) ] );
					} );
					$grid.append( emptyCells[f][1] );
				return true;
			} );
		}
	} );
} );

jQuery( function( $ ) {
	//初期表示
	$( ".is-tab li:first" ).addClass( "is-active" ).show();//tabs内最初のliに.activeを追加
	$( ".is-tab-content:first" ).show();//最初の.tab_contentを表示
	//タブクリック時
	$( ".is-tab" ).children().click( function() {
		$( ".is-tab" ).children().removeClass( "is-active" );//.is-activeを外す
		$( this ).addClass( "is-active" );//クリックタブに.is-activeを追加
		$( ".is-tab-content" ).hide();//全ての.tab_contentを非表示
		let activeTab = $( this ).find( "a" ).attr( "href" );//アクティブタブコンテンツ
		activeTab = activeTab.replace( "bt", "" );
		$( activeTab ).fadeIn();//アクティブタブコンテンツをフェードイン
		return false;
	} );
	return false;
} );
